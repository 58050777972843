.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.flex {
  display: flex;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
}
.items-center {
  align-items: center;
}
.shrink-0 {
  flex-shrink: 0;
}
.mr-5 {
  margin-right: 1.25rem;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.w-6 {
  width: 1.5rem;
}
.h-6 {
  height: 1.5rem;
}

a {
  color: inherit;
  text-decoration: inherit;
  text-decoration-line: ;
  text-decoration-thickness: ;
  text-decoration-style: ;
  text-decoration-color: ;
}
.text-slate-400 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
  margin-top: 10px;
}
.text-slate-200 {
  display: flex;
  align-items: center;
  vertical-align: middle;
}
a,
button {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    -webkit-backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}
menu,
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.App-header {
  background-color: #1b1d20;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.inlineUsername {
  vertical-align: middle;
  margin-left: 10px;
}
